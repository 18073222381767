
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseRabotaAgroBannerSearchForm from '@rshb/rshb-vue-kit/src/projects/rabotaAgro/forms/BaseRabotaAgroBannerSearchForm.vue'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

@Component({
  components: {
    BaseRabotaAgroBannerSearchForm,
    AppLazyImage
  }
})
export default class MainAgroBanner extends Vue {
  @Prop({
    default: ''
  })
  value!: string

  @Prop({
    default: ''
  })
  name!: string

  @Prop({
    default: ''
  })
  description!: string

  @Prop({
    default: ''
  })
  background!: string

  @Prop({
    default: ''
  })
  image!: string

  @Prop({
    default: ''
  })
  imageTablet!: string

  @Prop({
    default: ''
  })
  imageMobileFirst!: string

  @Prop({
    default: ''
  })
  imageMobileSecond!: string

  @Prop({
    default: 'Поиск по вакансиям'
  })
  searchPlaceholder!: string

  @Prop({
    default: 'Поиск'
  })
  searchButtonTitle!: string

  bannerImage = '/images/event/bannerImage.png'
  bannerMobile = '/images/event/bannerMobile.png'

  onClickSearchButton() {
    this.$emit('click-search-button', this.value)
  }

  onInputSearch(value) {
    this.$emit('input', value)
  }
}
