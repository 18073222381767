
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import RoundButton from '~/components/UI/RoundButton.vue'

@Component({
  components: { RoundButton }
})
export default class extends Vue {
  @Prop({
    default: () => ({
      loop: false,
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 10,
      navigationId: ''
    })
  })
  readonly options!: any

  @Prop({
    default: () => ({
      loop: false,
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 10,
      navigationId: ''
    })
  })
  readonly tabletOptions!: any

  @Prop({
    default: () => ({
      loop: false,
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 10,
      navigationId: ''
    })
  })
  readonly mobileOptions!: any

  @Prop({
    default: () => []
  })
  readonly cards!: any[]

  @Prop({
    default: false
  })
  readonly sideControls!: boolean

  parseNavigation(options) {
    if (options.navigationId) {
      options.navigation = {
        nextEl: `#s-${options.navigationId}-next`,
        prevEl: `#s-${options.navigationId}-prev`
      }
    }
    if (options.pagination) {
      options.pagination = {
        el: '.swiper-pagination'
      }
    }
    return options
  }

  get sliderOptions() {
    return this.parseNavigation({ ...this.options })
  }

  get tabletSliderOptions() {
    return this.parseNavigation({ ...this.tabletOptions })
  }

  get mobileSliderOptions() {
    return this.parseNavigation({ ...this.mobileOptions })
  }

  get showNavigation() {
    if (
      this.options.navigationId &&
      (this.sliderOptions.loop || this.cards.length > this.sliderOptions.slidesPerView)
    ) {
      return true
    } else {
      return false
    }
  }
}
