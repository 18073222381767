
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import BreadCrumbs from '~/components/UI/BreadCrumbs.vue'

@Component({
  components: { BreadCrumbs, AppLazyImage }
})
export default class ScholarshipProgramBanner extends Vue {
  @Prop({
    default: ''
  })
  name!: string

  @Prop({
    default: ''
  })
  description!: string

  @Prop({
    default: 'Подать заявку'
  })
  buttonName!: string

  @Prop({
    default: ''
  })
  image!: string

  @Prop({
    default: true
  })
  isBreadcrumbs!: boolean

  @Prop({
    default: true
  })
  isJoinButton!: boolean

  onClickButtonApplication() {
    this.$emit('on-click-button-application')
  }

  onClickButtonJoin() {
    this.$emit('on-click-button-join')
  }
}
